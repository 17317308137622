<template>
  <div class="w-full flex flex-col items-center">
    <UButton
      v-if="joinButton"
      color="green"
      class="mt-4 text-white text-xl md:text-3xl tracking-wide uppercase font-bold whitespace-nowrap"
      style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5)"
      size="xl"
      :to="currentSite.natsJoinUrl"
    >
      Get your password now
    </UButton>

    <div class="py-6">
      <USelect
        v-model="dropdownSelection"
        :options="dropdownLinks"
        color="white"
        variant="outline"
        size="xl"
        placeholder="Support &amp; Other Links"
      >
        <template #trailing>
          <font-awesome-icon
            icon="fa-light fa-arrow-up-arrow-down"
            class="w-5 h-5"
          />
        </template>
      </USelect>
    </div>

    <Divider />

    <div
      class="w-full max-w-7xl flex flex-col justify-start items-start gap-1 p-3 xl:px-0 pb-20 md:pb-3 text-[#6d6d6d]"
    >
      <NuxtLink to="/2257">
        18 U.S.C. § 2257 Record-Keeping Requirements Compliance Statement
      </NuxtLink>
      <NuxtLink to="/content_removal">
        <p>Report Content</p>
      </NuxtLink>
      <p>
        For billing inquiries or to cancel your membership, please visit
        <a href="https://segpay.com" target="_blank">segpay.com</a>
        , or
        <a href="https://epoch.com" target="_blank">epoch.com</a>
        , our authorized sales agents.
      </p>
      <p v-if="currentSite">
        Copyright &copy; {{ new Date().getFullYear() }} {{ currentSite.name }}.
        All rights reserved
      </p>
      <p v-for="(line, index) in filteredFooterLines" :key="index">
        {{ line.text }}
      </p>
    </div>
  </div>
</template>

<script setup>
  defineProps({
    joinButton: {
      type: Boolean,
      default: true,
    },
  })

  const currentSite = inject("currentSite")

  const geoCountry = inject("geoCountry")
  const inAgeRestrictedLocation = inject("inAgeRestrictedLocation")
  const filteredFooterLines = computed(() => {
    return currentSite.value.footerLines.filter((line) => {
      // For US viewers in an age-restricted region, skip lines mentioning "las vegas"
      if (["US"].includes(geoCountry) && inAgeRestrictedLocation) {
        if (/las vegas/i.test(line.text)) {
          return false
        }
      }
      // For GB or UK viewers in an age-restricted region, skip lines mentioning "england"
      if (["GB", "UK"].includes(geoCountry) && inAgeRestrictedLocation) {
        if (/england/i.test(line.text)) {
          return false
        }
      }
      return true
    })
  })

  const dropdownSelection = ref("")
  const dropdownLinks = [
    {
      label: "Billing Support",
      value: "https://membermaxhelp.com",
    },
    {
      label: "Epoch.com",
      value: "https://epoch.com",
    },
    {
      label: "SegPay.com",
      value: "https://cs.segpay.com",
    },
    {
      label: "CCBill Billing Support",
      value: "https://support.ccbill.com",
    },
    {
      label: "WTSeTicket.com Support",
      value: "https://wtseticket.com",
    },
    {
      label: "Webmasters",
      value: "https://fuckyoucash.com",
    },
    {
      label: "Contact Us",
      value: "mailto:cvv@cvvhelp.net",
    },
    {
      label: "Terms & Conditions",
      value: "/terms",
    },
    {
      label: "Privacy Policy",
      value: "/privacy",
    },
    {
      label: "Report Content",
      value: "/content_removal",
    },
  ]

  const handleSelection = () => {
    if (dropdownSelection.value) {
      window.location.href = dropdownSelection.value
    }
  }

  watch(() => dropdownSelection.value, handleSelection)
</script>
